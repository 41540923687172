import React from 'react';
import PropTypes from 'prop-types';
// import { MdChevronLeft, MdChevronRight } from '@react-icons/all-files/md';
import { HiChevronUp } from '@react-icons/all-files/hi/HiChevronUp';
import { HiChevronDown } from '@react-icons/all-files/hi/HiChevronDown';
import { HiChevronLeft } from '@react-icons/all-files/hi/HiChevronLeft';
import { HiChevronRight } from '@react-icons/all-files/hi/HiChevronRight';
// import CustomLink from '../link';
import NextLink from '../nextLink';
import styles from './index.module.css';

const PageButton = ({
    type,
    href,
    onClick,
    disabled,
    ...restProps
}) => {
    const icon = (
        type === 'prev' ? <HiChevronLeft color="#fff" size={20} /> : <HiChevronRight color="#fff" size={20} />
    );
    return (
        <button
            className={styles['page-button']}
            onClick={onClick}
            type="button"
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {
                href && !disabled ? (
                    <NextLink
                        linkProps={{
                            href,
                        }}
                        anchorProps={{
                            className: styles.link,
                            rel: type,
                            onClick,
                        }}
                    >
                        { icon }
                    </NextLink>
                    // <CustomLink
                    //     className={styles.link}
                    //     rel={type}
                    //     grayVisited={false}
                    //     to={href}
                    // >
                    //     { icon }
                    // </CustomLink>
                ) : icon
            }
        </button>
    );
};

PageButton.propTypes = {
    type: PropTypes.oneOf(['prev', 'next']),
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

PageButton.defaultProps = {
    type: 'prev',
    href: '',
    onClick: () => {},
    disabled: false,
};

const Pagination = ({
    total,
    currentPage,
    prevHref,
    nextHref,
    onChange,
}) => {
    const onPageChange = (pageNum) => {
        onChange(pageNum);
    };

    const onSelectChange = (event) => {
        onPageChange(+event.target.value);
    };

    const hasPrev = currentPage !== 1;
    const hasNext = currentPage !== total;

    const renderSelectIcon = () => (
        <>
            <HiChevronUp
                className={`${styles['select-icon-up']} ${!hasPrev ? styles.disabled : ''}`}
                style={{ marginBottom: -2 }}
                size={12}
            />
            <HiChevronDown
                className={`${styles['select-icon-down']} ${!hasNext ? styles.disabled : ''}`}
                style={{ marginTop: -2 }}
                size={12}
            />
        </>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <PageButton
                    type="prev"
                    disabled={!hasPrev}
                    href={prevHref}
                    onClick={() => { onPageChange(currentPage - 1); }}
                />
                <div className={styles['vertical-line']} />
                <div className={styles['select-wrapper']}>
                    <div className={styles['select-container']}>
                        <div className={styles['select-icon']}>
                            {
                                renderSelectIcon(hasPrev, hasNext)
                            }
                        </div>
                        <select
                            className={styles.select}
                            onChange={onSelectChange}
                            value={currentPage}
                        >
                            {
                                Array(total).fill(1).map((foo, index) => (
                                    <option
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`pagination-${index}`}
                                        value={index + 1}
                                    >
                                        { index + 1 }
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className={styles['vertical-line']} />
                <PageButton
                    type="next"
                    disabled={!hasNext}
                    href={nextHref}
                    onClick={() => { onPageChange(currentPage + 1); }}
                />
            </div>
        </div>
    );
};

Pagination.propTypes = {
    total: PropTypes.number,
    currentPage: PropTypes.number,
    prevHref: PropTypes.string,
    nextHref: PropTypes.string,
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    total: 1,
    currentPage: 1,
    prevHref: '',
    nextHref: '',
    onChange: () => {},
};

export default Pagination;
