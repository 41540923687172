const TextZh = {
    NOW: '刚刚',
    MINAGO: '分钟前',
    HOURAGO: '小时前',
    YESTERDAY: '昨天',
};

export const parseDate = (time, format) => {
    // based on http://php.net/manual/en/datetime.createfromformat.php
    const timeObj = new Date(time);
    if (Number.isNaN(timeObj.valueOf())) return 'Invalid Date';

    return format.replace(/\w/g, (c) => {
        switch (c) {
            // Year
            case 'Y':
                return timeObj.getFullYear();
            case 'y':
                return (`0${timeObj.getYear()}`).substr(-2);
            // Months
            case 'm':
                return (`0${timeObj.getMonth() + 1}`).substr(-2);
            case 'n':
                return timeObj.getMonth() + 1;
            // Date
            case 'd':
                return (`0${timeObj.getDate()}`).substr(-2);
            case 'j':
                return timeObj.getDate();
            // Hours
            case 'H':
                return (`0${timeObj.getHours()}`).substr(-2);
            case 'G':
                return timeObj.getHours();
            // Minutes
            case 'i':
                return (`0${timeObj.getMinutes()}`).substr(-2);
            // Seconds
            case 's':
                return (`0${timeObj.getSeconds()}`).substr(-2);
            // Milliseconds
            case 'I':
                return (`00${timeObj.getMilliseconds()}`).substr(-3);
            // Unix time
            case 'U':
                return parseInt(timeObj.getTime() / 1000, 10);
            // nothing case
            default:
                return c;
        }
    });
};

const parseRelativeDate = (time, detailed = false) => {
    const curTime = new Date();
    const thisTime = new Date(time);
    if (Number.isNaN(thisTime.valueOf())) return 'Invalid Date';

    // 一分钟内
    if (curTime - thisTime < 60e3) {
        return TextZh.NOW;
    }
    // 一小时内
    if (curTime - thisTime < 3600e3) {
        return `${Math.floor((curTime - thisTime) / 60e3)} ${TextZh.MINAGO}`;
    }

    // 今天 00:00:00.000 的时间戳
    const todayStart = curTime - (curTime.getHours() * 3600e3)
        - (curTime.getMinutes() * 60e3) - (curTime.getSeconds() * 1e3)
        - curTime.getMilliseconds();

    if (thisTime >= todayStart) {
        return `${Math.floor((curTime - thisTime) / 3600e3)} ${TextZh.HOURAGO}`;
    }
    // // 今天内
    // if (thisTime >= todayStart) {
    //     return parseDate(thisTime, 'H:i');
    // }
    // // 昨天内
    // if (thisTime >= todayStart - (24 * 3600e3)) {
    //     return `${TextZh.YESTERDAY} ${parseDate(thisTime, 'H:i')}`;
    // }
    // 今年内，因为是移动端，所以不需要加时间
    if (thisTime.getFullYear() === curTime.getFullYear()) {
        if (detailed) {
            return parseDate(thisTime, 'm-d H:i');
        }
        return parseDate(thisTime, 'n 月 j 日');
    }
    // 非今年内
    if (detailed) {
        return parseDate(thisTime, 'Y-m-d H:i');
    }
    return parseDate(thisTime, 'Y 年 n 月 j 日');
};

export default parseRelativeDate;
