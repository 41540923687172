import React from 'react';
import PropTypes from 'prop-types';
import { RiFileListLine } from '@react-icons/all-files/ri/RiFileListLine';
import PaginationIcon from './paginationIcon';

const PaginationModeIcon = ({ isPaginationMode }) => (
    isPaginationMode ? <RiFileListLine color="#1a1a1b" /> : <PaginationIcon color="#1a1a1b" />
);

PaginationModeIcon.propTypes = {
    isPaginationMode: PropTypes.bool.isRequired,
};

export default PaginationModeIcon;
