import Router from 'next/router';
import { getUserInfo } from '../services/user';
import { GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS } from '../ducks/user';

const redirect = ({ res, to, isServer }) => {
    if (isServer) {
        // server side redirect
        res.writeHead(302, {
            Location: to,
        });
        res.end();
    } else {
        // client side redirect
        Router.push(to);
    }
};

// this function should be called before any data fetch
// because in some cases, this function will clear the whole state
export default ({
    ctx, store, forceHome = false, forceRedirect = false,
}) => {
    const { req, res } = ctx;
    const options = {};
    const isServer = !!req;
    if (isServer) {
        // pass cookie to the request
        options.headers = {
            Cookie: req.headers.cookie,
        };
    }
    // need header from response
    options.raw = true;

    return getUserInfo(options).then((rawResponse) => rawResponse.json()
        .then((data) => {
            store.dispatch({
                type: GET_USER_INFO_SUCCESS,
                payload: data,
            });
            // pass cookie to the client
            if (isServer) {
                res.setHeader('set-cookie', rawResponse.headers.get('set-cookie'));
            }
            if (forceHome) {
                redirect({
                    res,
                    to: '/',
                    isServer,
                });
            }
        })).catch((err) => {
        // no user info found
        if (!forceRedirect && err.message === '未登录') {
            // no need to show the error message
            // if the page is accessible without auth
        } else {
            store.dispatch({
                type: 'popup/SHOW',
                payload: {
                    message: err.message,
                },
            });
        }
        const { isLoggedIn } = store.getState().user;

        // reset user info
        store.dispatch({
            type: GET_USER_INFO_ERROR,
            payload: err,
        });

        // previously logged in user
        // now the login state is invalid for some reason
        // so clear all other state
        // in case there is a privileged state
        if (isLoggedIn) {
            store.dispatch({
                type: 'RESET',
            });
        }

        if (forceRedirect) {
            redirect({
                res,
                to: '/login',
                isServer,
            });
        }
    });
};
