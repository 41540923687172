import React, {
// useRef
} from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

// ios sometimes not trigger on first tap issue
const NextLink = ({
    linkProps,
    anchorProps,
    children,
}) => {
    const { onClick } = anchorProps;
    const handler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) {
            onClick(e);
        }
    };
    // only execute once between these 2 events
    // const isTapped = useRef(false);

    // const onTouchStartHandler = (e) => {
    //     handler(e);
    //     isTapped.current = true;
    // };

    // const onClickHandler = (e) => {
    //     if (isTapped.current) {
    //         isTapped.current = false;
    //     } else {
    //         handler(e);
    //     }
    // };

    const onClickHandler = (e) => {
        handler(e);
    };

    const eventHandlerProps = {
        // onTouchStart: onTouchStartHandler,
        onClick: onClickHandler,
    };

    return (
        <Link prefetch={false} {...linkProps}>
            <a {...anchorProps} {...eventHandlerProps}>
                {children}
            </a>
        </Link>
    );
};

NextLink.propTypes = {
    linkProps: PropTypes.shape({}),
    anchorProps: PropTypes.shape({
        onClick: PropTypes.func,
    }),
    children: PropTypes.node,
};

NextLink.defaultProps = {
    linkProps: {},
    anchorProps: {},
    children: null,
};

export default NextLink;
