import React from 'react';
import PropTypes from 'prop-types';
// import { AiOutlineWeibo } from '@react-icons/all-files/ai';
import styles from './index.module.css';

const SideFooter = ({ style }) => (
    <div className={styles.container} style={style}>
        <div className={styles['margin-bottom']}>
            <a className={`${styles.link}`} href="mailto:contact@sixchan.com">联系我们</a>
            <span className={styles.dot}>·</span>
            <a className={`${styles.link}`} href="mailto:bug@sixchan.com">BUG反馈</a>
        </div>
        {/* <div className={styles['margin-bottom']}>
            <a className={`${styles.follow} ${styles.link}`} target="_blank" rel="noreferrer" href="https://weibo.com/u/7565954707">
                关注我们
                <AiOutlineWeibo className={styles.weibo} size={18} />
            </a>
        </div> */}
        <div className={`${styles.company}`}>
            ©2022 SixChan
        </div>
    </div>
);

SideFooter.propTypes = {
    style: PropTypes.shape({}),
};

SideFooter.defaultProps = {
    style: {},
};

export default SideFooter;
