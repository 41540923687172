import React from 'react';
import PropTypes from 'prop-types';

const PaginationIcon = ({ color }) => (
    <svg stroke={color} fill={color} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z" />
        </g>
    </svg>
);

PaginationIcon.propTypes = {
    color: PropTypes.string,
};

PaginationIcon.defaultProps = {
    color: '#1a1a1b',
};

export default PaginationIcon;
