import React from 'react';
// import Helmet from 'react-helmet';
import Head from 'next/head';
import PropTypes from 'prop-types';

const DESCRIPTION = 'SixChan/6chan/66chan/六叶频道，是一个类似reddit/4chan/贴吧的中文社区，在这里大家可以在无任何隐私风险的环境中畅聊自己感兴趣的话题和内容。六叶频道尊重每一位用户的隐私和发言，在尊重版规的前提下，六叶频道不会干涉任何用户的发帖权利和帖子内容。';

const CustomHelmet = ({
    title, description, suffix, moreSuffix, keywords, replaceKeyword,
}) => (
    <Head>
        <title>{`${title}${suffix ? ' - 六叶频道' : ''}${moreSuffix ? ` - ${moreSuffix}` : ''}`}</title>
        <meta name="description" content={description || DESCRIPTION} />
        {
            replaceKeyword ? (
                <meta name="keywords" content={keywords} />
            ) : (
                <meta name="keywords" content={`中文,论坛,社区,二次元,4chan,6chan,reddit${keywords ? `${keywords}` : ''}`} />
            )
        }
    </Head>
);

CustomHelmet.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    suffix: PropTypes.bool,
    moreSuffix: PropTypes.string,
    keywords: PropTypes.string,
    replaceKeyword: PropTypes.bool,
};

CustomHelmet.defaultProps = {
    title: '创建社区，分享内容',
    description: DESCRIPTION,
    suffix: true,
    moreSuffix: '隐私为上的中文社区',
    keywords: '',
    replaceKeyword: false,
};

export default CustomHelmet;
